import { LoadingButton } from '@mui/lab'
import { Button, Typography } from '@mui/material'
import React from 'react'

const Btn = (props) => {

  let properties = {
    height: '50px',
    backgroundColor: "#fff",
    color: "#000",
    fontSize: '18px'
  }

  if (props.small) {
    properties = {
      height: '36px',
      fontSize: '14px',
      color: "#000",
      backgroundColor: "#FFF",
    }
  }

  if (props.mini) {
    properties = {
      height: "26px",
      fontSize: "12px",
      fontWeight: "700",
      color: "#000",
      backgroundColor: "#FFF"
    }
  }

  if (props.backgroundColor) properties.backgroundColor = props.backgroundColor
  if (props.width) properties.width = props.width
  if (props.height) properties.height = props.height
  if (props.color) properties.color = props.color
  if (props.fontSize) properties.fontSize = props.fontSize
  properties.fontFamily = props.fontFamily || "Roboto"

  const onDisabledClick = props.onDisabledClick ? props.onDisabledClick : () => {}

  return (
    <LoadingButton
      disabled={props.disabled} // Restore disabled prop for styling
      variant="contained"
      sx={{
          backgroundColor: properties.backgroundColor,
          borderRadius:'62px',
          height: properties.height,
          width: properties.width,
          textTransform: 'none',
          cursor: props.disabled ? 'not-allowed' : 'pointer', // Show not-allowed cursor when disabled
          pointerEvents: 'auto', // Override Material-UI's disabled pointer-events
          "&:hover": {
            backgroundColor: properties.backgroundColor === "#82FFB4" ? 
              "#45d47c" : // much darker green on hover
              properties.backgroundColor, // keep original color for other buttons
            opacity: properties.backgroundColor !== "#82FFB4" ? 0.9 : 1 // reduce opacity for non-green buttons
          },
          "&.Mui-disabled": {
            pointerEvents: 'auto'
          }
      }}
      onClick={(e) => {
        if (props.loading) return;
        if (props.disabled) {
          onDisabledClick();
        } else {
          props.onClick();
        }
      }}
      loading={props.loading}
    >
      {
        
        <Typography
          color={properties.color}
          fontFamily={properties.fontFamily}
          fontSize={properties.fontSize}
          fontStyle={'normal'}
          fontWeight={properties.fontWeight ? properties.fontWeight : '400'}
          lineHeight={'27px'}
        >
          {props.children}
        </Typography>
      }
    </LoadingButton>
  )
}

export default Btn