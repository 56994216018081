import styled from '@emotion/styled'
import { Typography } from '@mui/material'
import React from 'react'
import token from '../../assets/token.svg'

const Tokens = (props) => {
  return (
    <Wrapper onClick={props.openTokens}>
      {
        <>
          <img 
            src={token} 
            height={"28px"}
            width={"28px"}
          />
          <Typography sx={{ 
            fontFamily: "Roboto",
            fontSize: "12px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "166%", /* 19.92px */
            letterSpacing: "0.4px",
            marginLeft: "5px",
            color: "#fff"
          }}>{props.value} Credits</Typography>
        </>
      }
      
    </Wrapper>
  )
}

export default Tokens

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background-color: #3A3A3A;
  border-radius: 40px;
  padding: 0 17px 0 9px;
`