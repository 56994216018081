import React, { useState, useRef } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { TABS } from '../../constants/status';
import ChairIcon from '@mui/icons-material/ChairOutlined';
import CategoryIcon from '@mui/icons-material/CategoryOutlined';
import CropFreeIcon from '@mui/icons-material/CropFreeOutlined';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import discord_white from '../../assets/discord-white-icon.svg'
import { Box } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Button from '../Button/Button';
import { useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';

function DropdownMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ref = useRef(null);  

  const { guiStore } = props.store

  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const Navigate = useNavigate();


  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActive = (tab) => {
    return props.selectedTab === tab
  }

  return (
    <div>
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={handleClick}
        ref={ref}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          "& .MuiMenu-list": {
            backgroundColor: "black !important"
          }
        }}
      >
        <MenuItem sx={{ color: "black", backgroundColor: "black", fontStyle: "italic" }}>
          <Button
            onClick={() => Navigate('/product-type')}
            small
            backgroundColor="#000"
            color="#fff"
            fontFamily="Syne"
            fontSize={"12px"}
            height={"30px"}
          >
            <Box display={"flex"} alignItems={"center"}>
              {guiStore.productType === "lifestyle" ? "Lifestyle shoot" : "Background generation"}
              <ArrowDropDownIcon sx={{ marginLeft: "2px" }} />
            </Box>
          </Button>
        </MenuItem>
        {props.isTrial && <MenuItem sx={{ color: "black", backgroundColor: "black", fontStyle: "italic" }} onClick={() => {}}>
          TRIAL
        </MenuItem>}
        <MenuItem sx={{ color: "black", backgroundColor: "black", fontStyle: "italic" }} onClick={() => {
          setAnchorEl(null);
          props.tabSelect(TABS.SCENE)
          props.tokensLeft()
        }}>
          {props.tokens} Credits
        </MenuItem>
        <MenuItem sx={{ color: isActive(TABS.SCENE) ? "white" : 'black', backgroundColor: "#000" }} onClick={() => {
          setAnchorEl(null);
          props.tabSelect(TABS.SCENE)
        }}>
          <LocalCafeIcon sx={{ color: isActive(TABS.SCENE) ? "white" : "black", fontSize: "20px", marginRight: "10px" }} />
          Home
        </MenuItem>
        <MenuItem sx={{ color: isActive(TABS.PRODUCTS) ? "white" : 'black', backgroundColor: "#000" }} onClick={() => {
          setAnchorEl(null);
          props.tabSelect(TABS.PRODUCTS)
        }}>
          <ChairIcon sx={{ color: isActive(TABS.PRODUCTS) ? "white" : "black", fontSize: "20px", marginRight: "10px" }}/>
          Products
        </MenuItem>
        <MenuItem sx={{ color: isActive(TABS.TEMPLATES) ? "white" : 'black', backgroundColor: "#000" }} onClick={() => {
          setAnchorEl(null);
          props.tabSelect(TABS.TEMPLATES)
        }}>
          <CategoryIcon sx={{ color: isActive(TABS.TEMPLATES) ? "white" : "black", fontSize: "20px", marginRight: "10px" }} />
          Templates
        </MenuItem>
        {/* <MenuItem sx={{ color: isActive(TABS.SETTINGS) ? "white" : 'black', backgroundColor: "#000" }} onClick={() => {
          setAnchorEl(null);
          props.tabSelect(TABS.SETTINGS)
        }}>
          <CropFreeIcon sx={{ color: isActive(TABS.SETTINGS) ? "white" : "black", fontSize: "20px", marginRight: "10px" }} />
          Settings
        </MenuItem> */}
        <MenuItem sx={{ color: isActive(TABS.SETTINGS) ? "white" : 'black', backgroundColor: "#000" }} onClick={() => {
          setAnchorEl(null);
          props.help()
        }}>
          <img src={discord_white} alt="discord" style={{ width: "20px", marginRight: "10px" }} />
          Discord
        </MenuItem>
        <MenuItem sx={{ color: 'black' }} onClick={props.billing}>Billing</MenuItem>
        <MenuItem sx={{ color: 'black', fontWeight: 700 }} onClick={props.logout}>Log out</MenuItem>
      </Menu>
    </div>
  );
}

export default inject('store')(observer(DropdownMenu))
